  
.conteneur-propos{
    display:flex;
    justify-content: center;
    margin-top:50px;
    margin-right:20px;
    margin-left:20px;
}
.logo-propos:hover{
    transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
  }

.logo-propos{
    position:absolute;
    bottom:340px;
    width:110px;
    height:110px;
    @media screen and (min-width:300px) and (max-width:810px){
        width:90px;
        height:90px;
        bottom:350px;
    }
}
.card-propos{
    position:relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding:20px;
    width:450px;
    height:350px;
    background-color: #B5BFAA;
    border:solid #20422D 3px;
    border-radius:50px;
    margin-top: 30px;
    margin-bottom:20px;
    font-size:18px;
    @media screen and (min-width:300px) and (max-width:500px){
        font-size:15px;
    }
    @media screen and (min-width:300px) and (max-width:400px){
        font-size:13px;
    }
}
.paragraphe{
    @media screen and (min-width:620px) and (max-width:648px){
        display:none;
    }
}
