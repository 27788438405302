.dashboard-container {
    width: 150px;
    
    background-color: #324A5E;
    height:100vh;
    position:sticky;
    top:-1px;
    z-index:6;
    @media screen and (min-width:300px) and (max-width:810px){
      width:100%;
      height:50px;
      padding:0px;
      display:flex;
  }
  }
  .dashboard-desktop{
    color:white;
    padding:20px;
    @media screen and (min-width:300px) and (max-width:810px){
      display:none;
  }
  }
  .dashboard-mobile{
    display:flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
    color:white;
    @media screen and (min-width:810px){
      display:none;
  }
  }
  .profile-image {
    width: 100%;
    border-radius: 50%;
    margin-bottom: 10px;
    @media screen and (min-width:300px) and (max-width:810px){
    width:35px;
    height:35px;
    margin-left:10px;
    
  }
  }
  
  .description-text {
    font-size: 14px;
    margin-bottom: 15px;
    
  }
  
  .dashboard-links {
    display: flex;
    flex-direction: column;
    margin-top:50px;
  }
  
  .link-item {
    text-decoration: none;
    width:100%;
    color: white;
    margin-bottom: 10px;
  }
  
  

  .rotateImage {

    transition: transform 0.3s ease;
    cursor: pointer;
    width: 24px;
    height: 14px;
  }
  
  .rotateImage--collapsed {
    transform: rotate(0deg);
  }
  
  .rotateImage--expanded {
    transform: rotate(90deg);
  }
  .buttonContainer{
    z-index: 0;
  }
  .buttonContainer img{
    width:30px;
    height:30px;
    margin-right:10px;
  }
  .collapseContent {
    background-color: #324A5E;
    padding: 10px;
    
    border-radius: 0px 0px 20px 20px;
    animation-timing-function: ease-out;
    animation-duration: 300ms;
    
  }

  .collapseContent.open {
    display: block;
    position:absolute;
    right:0px;
    z-index: 4;
    animation: expandAnimation 300ms ease-out;
  }
  
  @keyframes expandAnimation {
    from {
      height: 0;
      opacity: 0;
    }
    to {
      height: auto;
      opacity: 1;
    }
  }
  .link-mobile{
    display:flex;
    flex-direction: column;
    align-content: flex-end;
    gap:10px;
  }
  .liens-mobile{
    display:flex;
    align-items: center;
    gap:10px;
  }
  .logo-mobile{
    width:30px;
    height:30px;
  }
  .liens-desktop{
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    background-color:#131F50;
    border-radius:30px;
    height:90px;
  }
  .liens-desktop:hover{
    transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
  }
  .logo-desktop{
    width:40px;
    height:40px;
  }
  .lock{
    position:absolute;
    left:5px;
    bottom:10px;
  }
  