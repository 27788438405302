.styleProjets{
    width:100lh;
    background-color:#485158 ;
    h1{
        color:white;
        margin-left:10px;
        margin-right:10px;
    }
    @media screen and (min-width:300px) and (max-width:810px){
        width:100%;
    }
}
.styleProjets h1{
    display:flex;
    justify-content: center;
    margin-bottom: 90px;
}

.card-container{
    display:flex;
    justify-content: center;
    width: 300px;
    height: 200px;
    @media screen and (min-width:300px) and (max-width:810px){
        width:290px;
        height:190px;
    }
}
.groupCard{
    display:flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    margin:auto;
    padding:20px;
    gap: 90px;
    
    @media screen and (min-width:300px) and (max-width:1200px){
        display:flex;
        flex-direction: column;
    }
}