.styleHome{
    width:100lh;
    flex:1;
    overflow-y: auto;
    background-color: #1a3e5c;
    @media screen and (min-width:300px) and (max-width:810px){
        width:100%;
    }
}
.bloc-propos{
    display:flex;
    justify-content: center;
    @media screen and (min-width:300px) and (max-width:1230px){
        flex-direction: column;
    }
}

.welcome-mobile{
    display:flex;
    justify-content: center;
    margin-top:10px;
    h2{color:white;}
    @media screen and (min-width:300px) and (max-width:810px){
            
            background-color: #728C7B;
            color:white;
            padding:20px;
        }
        
        @media screen and (min-width:300px) and (max-width:810px){
            margin-top:0px;
        }
}