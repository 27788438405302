body {
  width:100%;
  margin: 0;
  font-family: 'Montserrat', Helvetica, sans-serif;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content-conteneur{
  display:flex;
  width:100%;
  height:100%;
  @media screen and (min-width:300px) and (max-width:810px){
    flex-direction: column;
}
@media screen and (min-width:0px) and (max-width:299px){
  display:none;
}
  
}

