.styleContacter{
    width:100lh;
    height:100vh;
    background-size: cover;
    background-position: center;
    @media screen and (min-width:300px) and (max-width:1200px){
      width:100%;
      height:100%;
  }
    }
    .conteneurContacter{
      color:white;
      display:flex;
      flex-direction:column;
      height:100vh;
      background-color: #485158;
      @media screen and (min-width:904px) and (max-width:1200px){
        width:100%;
        padding-bottom:40px;
       }
       @media screen and (min-width:300px) and (max-width:904px){
        height:100%;
        width:100%;
    }
    }
    .titreServices{
      display:flex;
      flex-direction: column;
      justify-content: center;
      padding:20px;
      p{font-size:18px;}
      @media screen and (min-width:300px) and (max-width:1200px){
        align-items: center;
    }
      p{
        @media screen and (min-width:300px) and (max-width:1200px){
          display:none;
      }
      }
      span{
        @media screen and (min-width:1200px){
          display:none;
      }
      }
    }
    .mappingContacter{
      display:flex;
      justify-content: space-around;
    }
    .bloc10{display:flex;
      justify-content:center;
      flex-direction: column;
      align-items: center;
      height:100%;
      gap: 3%;
      @media screen and (min-width:300px) and (max-width:1200px){
        display:none;
    }}
    
    .bloc20{
      display:none;
      @media screen and (min-width:810px) and (max-width:1200px){
        display:flex;
        justify-content:center;
        flex-wrap: wrap;
        align-items: center;
        height:100%;
        gap: 70px;
        margin-top:30px;
        padding-bottom:20px;
        img{width: 100px;
          height: 100px;
          object-fit:cover;
          @media screen and (min-width:300px) and (max-width:400px){
            width:80px;
            height:80px;
        }}
    }
    @media screen and (min-width:300px) and (max-width:810px){
      display:flex;
        justify-content:center;
        flex-direction: column;
        align-items: center;
        height:100%;
        gap: 70px;
        margin-top:50px;
        padding-bottom:20px;
        img{width: 100px;
          height: 100px;
          object-fit:cover;
          @media screen and (min-width:300px) and (max-width:400px){
            width:80px;
            height:80px;
        }}
    }
    }
    .conteneur-bloc20{
      position:relative;
      background-color:#131F50;
      border-radius:50px;
      width:350px;
      height:250px;
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img{
        position:absolute;
        bottom:200px;
        left:120px;
        @media screen and (min-width:300px) and (max-width:400px){
          bottom:140px;
          left:100px;
      }
      }
      @media screen and (min-width:300px) and (max-width:400px){
        width:280px;
        height:180px;
    }
    }
      
      .bloc10 img{width: 150px;
      height: 150px;
      object-fit:cover;
      cursor:pointer
      
      }
      .bloc10 img:hover{
        transform: scale(1.1);
        transition: transform 0.3s ease-in-out;
        @media screen and (min-width:300px) and (max-width:1200px){
          transform:none;
      }
      }    
    
      .texteContacter {
        position: absolute;
        top:13.1%;
        left:30%;
        
        width: 50%;
        height: 50%;
        
        
        
      }
    .screen{
        position:absolute;
        top:31%;
        left:25.9%;
        width:13.6%;
        height:17.5%
        
    }
    
    .computerContacter{
      
      position: relative;
      width:100%;
      height: 100vh;
      @media screen and (min-width:300px) and (max-width:1200px){
        display:none;
    }
    }
    
    .laptop{
      
      width:100%;
      height:100%;
      
    }
    .descriptifContacter{
      position:absolute;
      
      color:white;
      font-size:larger;
      font-weight: bolder;
      
      width:100%;
      height:100%;

      
      
    }
.styleTexteServices{
    width:100%;
    height: 15%;
    
    background-color:#E4D9C7;
    
    
    
}
.screenTitre{
  position: absolute;
  left:10%;
  top:1%;
  width:40%;
  height:10%;
  display:flex;
  align-items: center;
  
  
}
.positionTexteServices{
  position:absolute;
  left:29%;
  top:23%;
  width:65%;
  height:40%;
  
  background-color: #B6C2AC;
  
  
}