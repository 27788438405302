.cardContact{
    display:flex;
    justify-content: center;
    margin-top:50px;
    margin-right:20px;
    margin-left:20px;
}
.img-contact:hover{
    transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
  }

.conteneur-cardContact{
    position:relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:20px;
    width:450px;
    height:350px;
    background-color: #FD8469;
    border:solid #BF213E 3px;
    border-radius:50px;
    margin-top: 30px;
    margin-bottom:20px;
    font-size:18px;
    @media screen and (min-width:300px) and (max-width:500px){
        font-size:15px;
    }
}


.img-contact{
    position:absolute;
    bottom:340px;
    
    width:110px;
    height:110px;
}
.logoContact{
    width:50px;
    height:50px;
}
.logo-contain{
    display:flex;
    justify-content: center;
    gap:10px;
}