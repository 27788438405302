.app-container {
  display: flex;
  width:100%;
}

.main-section {
  flex-grow: 1;
  height: 90vh;
  width: 100%; 
  background-color: #728C7B;
  position: relative;
  @media screen and (min-width:300px) and (max-width:810px){
    display:none;
  }
  
}

.main-section img{
  width:12%;
  height:20%;
  @media screen and (min-width:800px) and (max-width:940px){
    width:120px;
    height:120px;
}
  @media screen and (min-width:620px) and (max-width:800px){
  width:100px;
  height:100px;
}
@media screen and (min-width:300px) and (max-width:620px){
  width:50px;
  height:50px;
}
  
}
.main-section img:hover{
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
  @media screen and (min-width:300px) and (max-width:810px){
    transform: none;
}
}
.icon-set{
  display:flex;
  gap:20px;
  width:100%;
  height:100%;
  flex-wrap:wrap;
  justify-content: center;
  padding-top:1%;
  @media screen and (min-width:620px) and (max-width:940px){
    gap:10px;
}
}
.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.styleImage {
  width: 100%;
  height: 90vh;
  opacity: 0.3;
}

.typing-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff; /* Couleur du texte */
  font-family: 'Arial', sans-serif;
  font-size: 24px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  @media screen and (min-width:620px) and (max-width:661px){
    top:362px;
}
  @media screen and (min-width:905px) and (max-width:940px){
    top:335px;
  }
  @media screen and (min-width:784px) and (max-width:800px){
    top:335px
}
@media screen and (min-width:300px) and (max-width:810px){
  display:none;
}

}

.cursor {
  display: inline-block;
  width: 8px;
  height: 24px;
  background-color: #fff;
  animation: blink 0.8s infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}


