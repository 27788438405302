/* Ajoutez ce style dans votre fichier CSS global ou dans un fichier dédié pour les modales */
.modal {
    display: block; /* Affiche la modale */
    position: fixed;
    z-index: 9;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.8); /* Fond noir semi-transparent */
    
  }
  
  .modal-content {
    background-color: #D8E4DF;
    margin: 5% auto; /* Centre la modale verticalement */
    padding: 20px;
    border: 1px solid #888;
    width: 80%;

    @media screen and (min-width:300px) and (max-width:810px){
      h2{
        display:none
      }
  }
  }
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  .project-images{
    display: flex;
    justify-content: center;
    width:100%;
    height:100%;
    flex-wrap: wrap;
    gap:2px;
    img{
      border:solid black 1px;
      border-radius: 10px;
    }

    @media screen and (min-width:300px) and (max-width:810px){
      flex-direction: column;
  }
  }
  .html0{
    width:50%;
    @media screen and (min-width:300px) and (max-width:810px){
      width:100%;
  }
    
  }
  .html1{
    width:30%;
    @media screen and (min-width:300px) and (max-width:810px){
      width:100%;
  }
  }
  .html2{
    width:30%;
    @media screen and (min-width:300px) and (max-width:810px){
      width:100%;
  }
  }
  .html3{
    width:30%;
    @media screen and (min-width:300px) and (max-width:810px){
      width:100%;
  }
  }
  .react0{
    width:49%;
    @media screen and (min-width:300px) and (max-width:810px){
      width:100%;
  }
    
  }
  .react1{
    width:352px;
    height:888px;
    @media screen and (min-width:300px) and (max-width:810px){
      width:100%;
  }
  }
  .react2{
    width:49%;
    @media screen and (min-width:300px) and (max-width:810px){
      width:100%;
  }
  }
  .react3{
    width: 30%;
    @media screen and (min-width:300px) and (max-width:810px){
      width:100%;
  }
  }
  .opti0{
    width:60%;
    @media screen and (min-width:300px) and (max-width:810px){
      width:100%;
  }
  }
  .opti1{
    width:60%;
    height:50%;
    @media screen and (min-width:300px) and (max-width:810px){
      width:100%;
  }
  }
  .opti2{
    width:50%;
    @media screen and (min-width:300px) and (max-width:810px){
      width:100%;
  }
  }
  .api0{
    width:49%;
    @media screen and (min-width:300px) and (max-width:810px){
      width:100%;
  }
  }
  .api1{
    width:49%;
    @media screen and (min-width:300px) and (max-width:810px){
      width:100%;
  }
  }
  .api2{
    width:49%;
    @media screen and (min-width:300px) and (max-width:810px){
      width:100%;
  }
  }
  .api3{
    width:49%;
    @media screen and (min-width:300px) and (max-width:810px){
      width:100%;
  }
  }
  .java0{
    width:50%;
    @media screen and (min-width:300px) and (max-width:810px){
      width:100%;
  }
  }
  .java1{
    width:50%;
    @media screen and (min-width:300px) and (max-width:810px){
      width:100%;
  }
  }
  .java2{
    width:50%;
    @media screen and (min-width:300px) and (max-width:810px){
      width:100%;
  }
  }
  .java3{
    width:49%;
    @media screen and (min-width:300px) and (max-width:810px){
      width:100%;
  }
  }
  .modal-text{
    display:flex;
    align-items: center;
    color:#324A5E;
    
    @media screen and (min-width:300px) and (max-width:810px){
      flex-direction: column;
      
  }
  }
.logoCover{
  width:250px;
  height:250px;
  @media screen and (min-width:300px) and (max-width:810px){
      width:150px;
      height:150px;
    }
}
.logoModal{
  width:30px;
  height:30px;
  margin-left:10px;
}
.modal-description{
  display:flex;
  flex-direction: column;
  margin-left:10px;
  p{
    font-weight: bold;
  }
  
}
.modal-github{
  display:flex;
  align-items: center;
  font-weight: bold;
}