.styleAuth{
    background-color: #485158;
    width:100%;
    
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;;
    p{
        color:white;
        font-size:18px;
    }
    
    label{
        color:white;
    }
    @media screen and (min-width:0px) and (max-width:620px){
        display:none;
    }
}